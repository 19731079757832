import { css } from "@emotion/react";
import { CallToAction, Quotes, Section } from "../components";
import { StyleShape2 } from "../components/Shapes";
import Theme from "../components/Theme";
import { CallToActionData } from "../queries/DataTypes";

const styles = {
  base: css`
    display: flex;
    flex-direction: column;
    position: relative;
    ::before {
      ${StyleShape2};
      left: -400px;
      top: -100px;
      transform: rotate(145deg);
    }
  `,
  layout: css`
    display: grid;
    grid-gap: 64px;
    align-items: center;
    grid-auto-flow: row;

    ${Theme.mq.md_} {
      grid-template-columns: 1fr minmax(400px, 1fr);
    }
  `,
  footnote: css`
    margin-top: 36px;
  `,
};

export interface SectionPartnerInfoProps extends CallToActionData {
  footnote?: string;
  quoteRefs?: Array<string>;
}

export const SectionPartnerInfo = ({ quoteRefs, footnote, ...other }: SectionPartnerInfoProps) => {
  return (
    <div css={styles.base}>
      <Section marginTop marginBottom sx={styles.layout}>
        <div>
          <CallToAction appearance="tertiary" {...other} />
          {footnote && (
            <div css={[Theme.typography.p, styles.footnote]} dangerouslySetInnerHTML={{ __html: footnote }} />
          )}
        </div>
        {quoteRefs && quoteRefs.length > 0 && <Quotes keys={quoteRefs} card />}
      </Section>
    </div>
  );
};
